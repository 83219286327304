<template>
  <div>
    <label v-if="label" :for="id">{{ label }}</label>
    <v-select
      @change.prevent
      :id="id"
      v-bind="$attrs"
      :options="options"
      v-model="selected"
      :placeholder="placeholder"
      :getOptionLabel="optionLabel"
      :reduce="optionValue"
      :filterBy="filterBy"
      :append-to-body="true"
      class="form-control"
      :class="validityClass">
      <template slot="open-indicator" slot-scope="{ attributes }">
        <img v-bind="attributes" src="../../assets/chevron_down_dark_green.svg" />
      </template>
      <template slot="no-options">
        {{ noOptions }}
      </template>
    </v-select>
    <div v-show="invalid" class="invalid-feedback" data-testid="search-select-error"> {{ invalidText }}</div>
    <div class="invalid-feedback html5-validation">{{ validationFeedback }}</div>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import latinize from 'latinize'

export default {
  inheritAttrs: false,
  components: { VSelect },
  props: {
    label: null,
    options: {
      type: Array,
      default() {
        return []
      }
    },
    value: null,
    validate: false,
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    },
    placeholder: '',
    invalidText: '',
    noOptions: '',
    optionLabel: {
      default() {
        return opt => opt
      }
    },
    optionValue: {
      default() {
        return opt => opt
      }
    },
    filterValue: {
      default() {
        return this.optionLabel
      }
    },
    filterBy: {
      default() {
        return (option, label, search) => {
          const normalize = value => latinize(value).toLowerCase()
          const value = this.filterValue(option)
          return normalize(value).includes(normalize(search))
        }
      }
    },
    id: {
      default() {
        return `select-${this._uid}`
      }
    }
  },
  watch: {
    validationFeedback(message) {
      this.input().setCustomValidity(message)
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$el.dispatchEvent(new CustomEvent('change', { bubbles: true }))
      }
    },
    invalid() {
      return this.validate && !this.value
    },
    validityClass() {
      return [{ 'is-invalid': this.invalid }, this.validationFeedback ? 'invalid' : 'valid']
    }
  },
  methods: {
    input() {
      return this.$el.querySelector('input')
    }
  },
  mounted() {
    this.input().setCustomValidity(this.validationFeedback)
  }
}
</script>


<style lang="scss">
@import 'node_modules/vue-select/src/scss/vue-select.scss';
@import './src/utilities/config';

.v-select {
  .vs__open-indicator {
    width: 15px;
    height: auto;
  }

  // Unset borders, margin and padding to use those provided by bootstrap
  // Otherwise, need to redefine all vue-select styles here
  .vs__dropdown-toggle {
    border: 0;
    display: -webkit-box;
  }

  .vs__search {
    margin: 0;
    padding: 0;
  }

  .vs__actions {
    margin: 0;
    padding: 0;
  }

  .vs__selected {
    margin: 0;
    padding: 0;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vs__selected-options {
    height: 20px;
  }

  // Replicates bootstrap-style focus
  &:focus-within {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}

// Replicates bootstrap-style invalid/valid
.was-validated .invalid {
  border-color: $form-feedback-invalid-color;
  padding-right: calc(1.5em + 0.75em);
  background-image: escape-svg($form-feedback-icon-invalid);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .valid {
  border-color: $form-feedback-valid-color;
}
</style>
